import React from 'react'
import {Container, Row, Col} from 'reactstrap'
import {useStaticQuery, graphql} from 'gatsby'
import { Link } from "gatsby"
import Heading from '../../../components/shared/heading'
import Text from '../../../components/shared/text'
import {AboutContentWrap, AboutContentTop, AboutList, AboutContentBottom} from './about-content-area.stc'

const AboutContentArea = (props) => {
    const aboutData = useStaticQuery(graphql `
        query AboutContentQuery {
            aboutJson(id: {eq: "about-page-content"}) {
                team_content {
                    heading
                    content {
                        text1
                        text2
                        text3
                        text4
                    }
                }
                philosophy_content {
                    heading
                    content {
                        text1
                        text2
                        text3
                        text4
                    }
                }
                philosophy_list {
                    heading
                    items
                }
                philosophy_content_bottom {
                    content {
                        text1
                        text2
                    }
                }
                values_content {
                    heading
                    content {
                        value1
                        text1
                        value2
                        text2
                        value3
                        text3
                        value4
                        text4
                    }
                }
            }
        }
    `)
    const {team_content, philosophy_content, philosophy_list, philosophy_content_bottom, values_content} = aboutData.aboutJson
    const {topHeadingCSS, textCSS, valueCSS, listHeadingCSS} = props;
    return (
        <AboutContentWrap>
            <Container>
                <Row className="justify-content-center">
                    <Col lg={10} xl={8}>
                        {team_content && (
                            <AboutContentTop>
                                {team_content.heading && <Heading {...topHeadingCSS}>{team_content.heading}</Heading>}
                                {team_content.content.text1 && <Text {...textCSS}>{team_content.content.text1}</Text>}
                                {team_content.content.text2 && <Text {...textCSS}>{team_content.content.text2}</Text>}
                                {team_content.content.text3 && <Text {...textCSS}>{team_content.content.text3}</Text>}
                                {team_content.content.text4 && <Link to="/our-team"><Text {...textCSS}>{team_content.content.text4}</Text></Link>}
                            </AboutContentTop>
                        )}
                        {philosophy_content && (
                            <AboutContentTop>
                                {philosophy_content.heading && <Heading {...topHeadingCSS}>{philosophy_content.heading}</Heading>}
                                {philosophy_content.content.text1 && <Text {...textCSS}>{philosophy_content.content.text1}</Text>}
                                {philosophy_content.content.text2 && <Text {...textCSS}>{philosophy_content.content.text2}</Text>}
                                {philosophy_content.content.text3 && <Text {...textCSS}>{philosophy_content.content.text3}</Text>}
                                {philosophy_content.content.text4 && <Text {...textCSS}>{philosophy_content.content.text4}</Text>}
                            </AboutContentTop>
                        )}
                        <Row>
                            {philosophy_list && (
                                <Col lg={10}>
                                    {philosophy_list.heading && <Heading {...listHeadingCSS}>{philosophy_list.heading}</Heading>}
                                    {philosophy_list.items && (
                                        <AboutList>
                                            {philosophy_list.items.map((item, i) => <li key={`left-list-${i}`}>{item}</li>)}
                                        </AboutList>
                                    )}
                                </Col>
                            )}
                        </Row>
                        {philosophy_content_bottom && (
                            <AboutContentBottom>
                                {philosophy_content_bottom.content.text1 && <Text>{philosophy_content_bottom.content.text1}</Text>}
                                {philosophy_content_bottom.content.text2 && <Text>{philosophy_content_bottom.content.text2}</Text>}
                            </AboutContentBottom>
                        )}
                        {values_content && (
                            <AboutContentTop>
                                {values_content.heading && <Heading {...topHeadingCSS}>{values_content.heading}</Heading>}
                                {values_content.content.value1 && <Text {...valueCSS}>{values_content.content.value1}</Text>}
                                {values_content.content.text1 && <Text {...textCSS}>{values_content.content.text1}</Text>}
                                {values_content.content.value2 && <Text {...valueCSS}>{values_content.content.value2}</Text>}
                                {values_content.content.text2 && <Text {...textCSS}>{values_content.content.text2}</Text>}
                                {values_content.content.value3 && <Text {...valueCSS}>{values_content.content.value3}</Text>}
                                {values_content.content.text3 && <Text {...textCSS}>{values_content.content.text3}</Text>}
                                {values_content.content.value4 && <Text {...valueCSS}>{values_content.content.value4}</Text>}
                                {values_content.content.text4 && <Text {...textCSS}>{values_content.content.text4}</Text>}
                            </AboutContentTop>
                        )}                        
                    </Col>
                </Row>
            </Container>
        </AboutContentWrap>
    )
}

AboutContentArea.defaultProps = {
    topHeadingCSS: {
        as: 'h4',
        color: 'secondaryColor',
        lineHeight: 1.375,
        mb: '17px'
    },
    textCSS: {
        mb: '26px'
    },
    valueCSS: {
        as: 'h5',
        mb: '7px'
    },
    listHeadingCSS: {
        as: 'h5',
        mb: '14px'
    }
}

export default AboutContentArea


  