import React from "react"
import Layout from "../containers/layout/layout"
import SEO from '../components/seo'
import Header from '../containers/layout/header'
import AboutTitleArea from '../containers/about/about-title-area'
import AboutBannerArea from '../containers/about/about-banner-area'
import AboutContentArea from '../containers/about/about-content-area'
import InstagramWrap from '../containers/global/instagram'
import Footer from '../containers/layout/footer'

const AboutPage = () => (
  <Layout>
    <SEO 
        title="About" 
        description="Learn all about Spectrum House"
        keywords={["autism", "adhd", "anxiety", "spectrum house", "mental health", "children", "assessment", "diagnosis", "psychologist", "psychology", "child"]}
    />
    <Header/>
    <div className="main-content">
        <AboutTitleArea/>
        <AboutBannerArea/>
        <AboutContentArea/>
        <InstagramWrap/>
    </div>
    <Footer/>
  </Layout>
)

export default AboutPage
